import createStore from "./create-store";

let reduxStore;
const getOrCreateStore = (initialState, initialPageModules) => {
  // Always create a new store on the server.
  if (typeof window === "undefined") {
    return createStore(initialState, initialPageModules);
  }

  // Create a singleton store on the client if it doesn't exist yet.
  if (!reduxStore) {
    reduxStore = createStore(initialState, initialPageModules);
  }

  return reduxStore;
};

export default getOrCreateStore;
