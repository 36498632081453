/**
 *
 * Constant data to be used in the app.
 */
export const PROTECTED_REGEX_BFF_APIS = [
  /\/add-to-collection/,
  /\/remove-from-collection/,
  /\/checkout/,
  /\/payment/,
  /\/common/,
  /\/get\/sopProducts/,
  /\/ci\//,
  /\/orders\/ordersDetail/,
  /\/get-event-notice/,
  /\/get-investigate-type/,
];
export const VISITOR_ENABLED_REGEX_BFF_APIS = [
  /\/add-to-cart/,
  /\/calculate-bundle-total/,
  /\/cart/,
  /\/batch-add-to-cart/,
  /\/markup-purchase-activity/,
  /\/query-proportion-combination/,
];

export const PROTECTED_REGEX_ROUTES = [
  /\/checkout/,
  /\/pickup\/amway-plaza\/list/,
  /\/invoice\/list/,
  /\/payment\/stored-cards\/list/,
  /\/cart\/checkout\/store-callback/,
  /\/payment\/list/,
  /\/pdm-plp-shop-by-redeem-points/,
  /\/shopping-by-redeem-points/,
  /\/store\/ok-mart\/callback/,
  /\/store\/family-mart\/callback/,
  /\/recipient-list/,
  /\/order-status/,

  // Customer identity module
  /\/orders/,
  /\/profile/,
  /\/address/,
  /\/additional/,
  /\/mylist/,
  /\/createList/,
  /\/specialproduct/,
  /\/bulkorders/,
  /\/onlinereturn/,
  /\/favorites/,
  /\/privilegeorders/,
  /\/a40stamps/,
  /\/product$/,
  /\/promotions$/,
  /\/priceChange\/sop/,
];

export const CART_CHECKOUT_ROUTES = [
  "/purchase-cart",
  "/cart",
  "/purchase-checkout",
  "/checkout",
  "/purchase-checkout-plaza",
  "/pickup/amway-plaza/list",
  "/purchase-checkout-invoice",
  "/invoice/list",
  "/purchase-saved-cards",
  "/payment/stored-cards/list",
  "/purchase-store-callback",
  "/cart/checkout/store-callback",
  "/purchase-checkout-payment-options",
  "/payment/list",
  "/shopping-by-redeem-points",
  "/store-ok",
  "/store/ok-mart/callback",
  "/store-family",
  "/store/family-mart/callback",
  "/purchase-checkout-recipient-list",
  "/recipient-list",
  "/payment-callback",
  "/payment/callback",
];

export const HIDE_HEADER_ROUTES = [
  ...CART_CHECKOUT_ROUTES,
  "/pdm-plp-free-shipping-products",
  "/pdm-plp-markup-purchase-products",
  "/free-shipping-recommended-products",
  "/markup-purchase-products",
  "/pdm-plp-shop-by-redeem-points",
  "/privacy-policy",
  "/terms-of-use",
];

export const MARKUP_CACHE_PAGE_LIST = [
  "/pdm-pdp",
  "/pdm-plp-markup-purchase-products",
  "/ci/login/login",
  "/ci/login/landing"
];



export const RECAPTCHA_THRESHOLD = 0.5;
