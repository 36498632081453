import React from 'react';
import Link from 'next/link';
import styles from './announcement-bar.scss';
// import { setAnnouncement } from "../../../redux/announcement-reducer";
import { setAnnouncement } from '@tw/product-discovery-merchandizing-cra/lib/home/redux/reducers';
import { useDispatch,useSelector } from 'react-redux';

function AnnouncementBar(props) {
  React.useEffect(() => {
    window.scrollTo(0, 10);
  }, []);
  const announcement = props.announcement;

  const dispatch = useDispatch();
  const breakpoint = useSelector((state) => state.breakpoint);
  let isDesktop = breakpoint !== "xs" && breakpoint !== "sm";

  const close = () => {
    dispatch(setAnnouncement(null));
  };

  if (!announcement) {
    return null;
  }
  const { message, link } = announcement;

  return (
    <>
      <div className={styles.announcement_bar_container}>
        <Link href={link?.href} passHref>
          <a target={link?.tabConfig === 'new' ? '_blank' : ''}>
            <div className={styles.empty_div}></div>
            <div className={ isDesktop ? styles.message : styles.messageMobile}>
              {message} {/* <b className={styles.read_more}>閱讀更多</b> */}
              <b className={styles.read_more}>{link?.title}</b>
            </div>
          </a>
        </Link>
        <div className={styles['close_icon']} onClick={close}>
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9.56488 7.98355L13.1444 4.40407C13.3514 4.19705 13.3514 3.8611 13.1444 3.65375L12.3126 2.82201C12.1056 2.61499 11.7697 2.61499 11.5623 2.82201L7.98316 6.40183L4.40368 2.82235C4.19666 2.61533 3.86071 2.61533 3.65335 2.82235L2.82195 3.65375C2.61493 3.86077 2.61493 4.19672 2.82195 4.40407L6.40143 7.98355L2.82195 11.563C2.61493 11.77 2.61493 12.106 2.82195 12.3134L3.65369 13.1451C3.86071 13.3521 4.19666 13.3521 4.40401 13.1451L7.98316 9.56527L11.5626 13.1448C11.7697 13.3518 12.1056 13.3518 12.313 13.1448L13.1447 12.313C13.3517 12.106 13.3517 11.7701 13.1447 11.5627L9.56488 7.98355Z"
              fill="#38539A"
            />
          </svg>
        </div>
      </div>
    </>
  );
}

export default AnnouncementBar;
