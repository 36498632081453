import axios from '@tw/shared-ui-components/lib/utils/axios_interceptor';
import Router from "next/router";

export const fetchTutorialsData = (pageId) => {
  if (typeof window === "undefined") return;
  const baseURL = window.appConfig?.PDM_BFF_API || "http://localhost:3002";
  return axios.post(`/tutorial/${pageId}`, {}, { baseURL });
};

export const getVAda = async () => {
  if (typeof window === "undefined") return;

  const baseURL = window.appConfig?.PDM_BFF_API || "http://localhost:3002";

  if (window.appConfig?.NODE_ENV === "dev") {
    return axios.post(`${baseURL}/anon/get-vada`, {});
  }
  const response = await getReCaptchaScore();
  if (response?.status === 200) {
    if (response?.data?.success) {
      return axios.post(`${baseURL}/anon/get-vada`, {});
    } else {
      Router.push("/page-not-found");
    }
  } else {
    return axios.post(`${baseURL}/anon/get-vada`, {});
  }
};

export const getReCaptchaScore = async () => {
  if (typeof window.grecaptcha === "undefined") return;
  const baseURL = window.appConfig?.PURCHASE_BFF_API || "http://localhost:3001";
  const siteKey = window.appConfig?.NEXT_PUBLIC_RECAPTCHA_SITE_KEY;
  const token = await window.grecaptcha.execute(siteKey, {
    action: "visitor_add_cart",
  });
  return axios.post(`${baseURL}/anon/common/verify-recaptcha`, {
    response: token,
  });
};
