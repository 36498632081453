import { connect } from 'react-redux';
import get from 'lodash/get';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { ToastContainer } from 'react-toastify';
import Header from '@tw/header-footer/lib/components/header/header';
import Footer from '@tw/header-footer/lib/components/footer/footer';
import Breakpoint from '@tw/style-guide/lib/components/utilities/breakpoint';
import AnnouncementBar from '../announcement-bar/announcement-bar';
import MiniCart from '@tw/header-footer/lib/components/miniCart/miniCart';
import StickyIconSet from '@tw/shared-ui-components/lib/components/stickyIconSet/stickyIconSet';
import Chatbot from '@tw/shared-ui-components/lib/components/Chatbot'
import AmwayInsider from '@tw/shared-ui-components/lib/components/AmwayInsider/AmwayInsider';
import { clearMarkupPurchaseInfo, dealWithMarkupPDPCaching } from '@tw/shared-ui-components/lib/utils/helper'
import styles from './layout.scss';
import { setBreakpoint } from '../../../redux/breakpoint-reducer';
import { CART_CHECKOUT_ROUTES, HIDE_HEADER_ROUTES, MARKUP_CACHE_PAGE_LIST } from '../../../utils/Constants/Data';
import { useEffect } from 'react';
import NextHead from "next/head";
const Layout = props => {
  const router = useRouter();

  const { children, headerData, footerData, breakpoint, announcement } = props;

  const ifShowHeaderFooter = !HIDE_HEADER_ROUTES.some(cRoute => router.route === cRoute);

  const ifClearMarkupCache = !MARKUP_CACHE_PAGE_LIST.some(cRoute => router.route === cRoute);

  const zeroPagePaddingRoutes = ['/pdm-home', '/pdm-static-page', ...CART_CHECKOUT_ROUTES];

  const addPagePadding = !zeroPagePaddingRoutes.some(cRoute => router?.route === cRoute);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'auto',
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  }, []);

  //  清除加价购浏览器缓存
  useEffect(() => {
    if (ifClearMarkupCache) {
      clearMarkupPurchaseInfo()
    } else {
      dealWithMarkupPDPCaching(router?.route)
    }
  }, [ifClearMarkupCache])
  return (
    <div className={styles.container}>
      {/* IMP: DO NOT CHANGE THE ifShowHeaderFooter logic which is implemented here because it will break navigation to Cart */}
      <div className={ifShowHeaderFooter ? styles.sticky_top_container : styles.hidden_container}>
        <AnnouncementBar announcement={announcement} />
        <Header headerData={headerData} breakpoint={breakpoint} Link={Link} />
      </div>
      <div className={`${styles.page_container} ${addPagePadding ? styles.page_padding : ''}`}>
        {!ifShowHeaderFooter && <MiniCart headerData={headerData} />}

        {children}
      </div>

      {/* IMP: DO NOT CHANGE THE ifShowHeaderFooter logic which is implemented here because it will break navigation to Cart */}
      <div className={ifShowHeaderFooter ? {} : styles.hidden_container}>
        <Footer footerData={footerData} breakpoint={breakpoint} Link={Link} />
      </div>

      <Breakpoint onBreakpointChange={props.setBreakpoint} />
      <ToastContainer />
      <AmwayInsider />
      <StickyIconSet
        bubbleCloseInSeconds={3}
        chatBotURL="https://care-qa.amwaynet.com.tw/live800/chatClient/chatbox.jsp?companyID=9058"
        excludeInURL={['/cart', '/checkout', '/payment/list']}
      />
      <Chatbot
        NextHead={NextHead}
        excludeInURL={['/cart', '/checkout', '/payment/list']}
      />
    </div>
  );
};

const mapStateToProps = state => ({
  headerData: get(state, 'headerState', {}),
  footerData: get(state, 'footerState', {}),
  breakpoint: get(state, 'breakpoint', 'lg'),
  announcement: get(state, 'homeState.announcement', null),
});

const mapDispatchToProps = dispatch => ({
  setBreakpoint: breakpoint => dispatch(setBreakpoint(breakpoint)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
