import Cookies from "universal-cookie";
import { checkAndRefreshToken } from "@tw/customer-identity-cra/lib/common/utils/login";
import {
  PROTECTED_REGEX_BFF_APIS,
  VISITOR_ENABLED_REGEX_BFF_APIS,
} from "../Constants/Data";
import jwt from "jsonwebtoken";
import { getVAda } from "../api";

import * as ls from "@tw/shared-ui-components/lib/utils/loginSession";

export const isProtectedApi = (url) =>
  PROTECTED_REGEX_BFF_APIS.some(
    (route) => url.indexOf("/anon/") == -1 && url.match(route)
  );

export const isVisitorEnabledApi = (url) =>
  VISITOR_ENABLED_REGEX_BFF_APIS.some((route) => url.match(route));

export const redirectToLogin = (redirectCode = "") => {
  if (typeof window === "undefined") return;

  console.log("_app.js: redirecting to login");
  window.location.href =
    "/login?exp=a" + redirectCode + "&h=" + btoa(window.location.pathname);
  // history.pushState({}, null, "/login");
};

export const getVisitorInfo = async ({ addConsigneeData = false }) => {
  if (typeof window === "undefined") return;

  // let vAda = localStorage.getItem(VADA_LS_KEY);

  const cookie = new Cookies();
  let vAda = cookie.get("vAda");
  const role = cookie.get("role") || "FOA";
  const loginType = cookie.get("loginType") || "V";
  const loginAdaType = cookie.get("loginAdaType") || "FOA";

  let accessToken = cookie.get("accessToken");

  const isExpired = !isTokenValid(accessToken);

  if (!vAda || !accessToken || isExpired) {
    const res = await getVAda();
    vAda = res?.data?.ada;

    accessToken = res?.data?.accessToken;
    cookie.set("vAda", vAda, ls.cookieOption(ls.loginCookieExp()));
    cookie.set(
      "accessToken",
      accessToken,
      ls.cookieOption(ls.loginCookieExp())
    );
    cookie.set("role", "FOA", ls.cookieOption(ls.loginCookieExp()));
    cookie.set("loginType", "V", ls.cookieOption(ls.loginCookieExp()));
    cookie.set("loginAdaType", "FOA", ls.cookieOption(ls.loginCookieExp()));
  }

  return {
    accessToken,
    visitorInfo: {
      loginAda: vAda,
      loginMemberType: role,
      loginType,
      loginAdaType,
      ...(addConsigneeData
        ? {
          consigneeAda: vAda,
          consigneeMemberType: role,
          consigneeType: loginType,
        }
        : {}),
    },
  };
};

export const getLoggedInUserInfo = async () => {
  if (typeof window === "undefined") return process.loggedInUserInfo || {};
  await checkAndRefreshToken();
  return getUserInfoFromCookie() || {};
};

export const getUserInfoFromCookie = (reqCookie) => {
  const cookie = reqCookie ? new Cookies(reqCookie) : new Cookies();
  const accessToken = cookie.get("accessToken");
  const ada = cookie.get("ada");
  const role = cookie.get("role");
  const loginType = cookie.get("loginType");
  return {
    accessToken,
    ada,
    role,
    loginType,
  };
};

export const removeUserInfoFromCookie = () => {
  if (typeof window === "undefined") return false;
  const cookie = new Cookies();

  cookies.set("vAda", "", ls.cookieOption(-1));
  cookies.set("accessToken", "", ls.cookieOption(-1));
  cookies.set("ada", "", ls.cookieOption(-1));
  cookies.set("role", "", ls.cookieOption(-1));
  cookies.set("loginType", "", ls.cookieOption(-1));

  return true;
};

export const isTokenValid = (t) => {
  if (t) {
    const tk = jwt.decode(t);
    if (tk?.exp) {
      return new Date().getTime() < tk.exp * 1000;
    }
    return false;
  }
  return "";
};

export const getAccessTokenHeaders = (accessToken) => {
  let headers = {};
  headers["Authorization"] = `Bearer ${accessToken}`;
  const payload = jwt.decode(accessToken);

  if (typeof window === "undefined") headers["User-Agent"] = payload?.ua;

  return headers;
};
