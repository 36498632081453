import { createStore as createDynamicStore } from "redux-dynamic-modules";
import { getThunkExtension } from "redux-dynamic-modules-thunk";
import baseReduxModule from "./base-redux-module";
import { composeWithDevTools as composeWithLogOnlyDevTools } from "redux-devtools-extension/logOnly";

const createStore = (initialState = {}, initialPageModules = []) => {
  // If `initialPageModules` is included, it means that the `initialState` likely has state that is
  // handled by the modules. We need to get rid of the modules' state keys from `initialState`
  // because the store needs to initialize with only the keys that are included in the base set of
  // reducers.
  const baseInitialState = Object.entries(initialState).reduce(
    (accum, [stateKey, state]) => {
      const initialStateContainsModuleKey = initialPageModules.some(
        (reduxModule) =>
          reduxModule.reducerMap && reduxModule.reducerMap[stateKey]
      );

      if (initialStateContainsModuleKey) {
        return accum;
      }

      accum[stateKey] = state;
      return accum;
    },
    {}
  );

  const store = createDynamicStore(
    {
      initialState: baseInitialState,
      extensions: [getThunkExtension()],

      // Use `logOnly` redux devtools when not in development. Otherwise, `redux-dynamic-modules`
      // automatically includes the fully-featured devtools.
      advancedComposeEnhancers:
        process.env.NODE_ENV !== "development"
          ? composeWithLogOnlyDevTools({})
          : undefined,
    },
    baseReduxModule
  );

  store.removePageModules = () => {};

  store.addPageModules = (pageModules) => {
    const { remove } = store.addModules(pageModules);

    store.removePageModules = remove;
    store.currentModule = pageModules[0].id;
    return remove;
  };

  // Use `initialState`'s keys that the modules will govern to preload the reducers we will be
  // adding to the store. This way, we can seed the store with data meant for the modules' reducers.
  // This only happens once when the store is first created.
  if (initialPageModules.length > 0) {
    const preloadedInitialModules = initialPageModules.map((reduxModule) => {
      if (!reduxModule.reducerMap) {
        return reduxModule;
      }

      const preloadedModuleReducers = Object.entries(
        reduxModule.reducerMap
      ).reduce((accum, [reducerKey, reducer]) => {
        accum[reducerKey] = (state = initialState[reducerKey], action) =>
          reducer(state, action);
        return accum;
      }, {});

      return {
        ...reduxModule,
        reducerMap: preloadedModuleReducers,
      };
    });

    store.addPageModules(preloadedInitialModules);
  }

  return store;
};

export default createStore;
