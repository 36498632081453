import axios from '@tw/shared-ui-components/lib/utils/axios_interceptor';
import Cookies from "universal-cookie";

import {
  isProtectedApi,
  redirectToLogin,
  getLoggedInUserInfo,
  getVisitorInfo,
  isTokenValid,
  isVisitorEnabledApi,
  getAccessTokenHeaders,
  removeUserInfoFromCookie,
} from "./helper";
import { logoutSession } from "@tw/customer-identity-cra/lib/common/utils/login";
import { toastMsg } from "@tw/shared-ui-components/lib/components/toastMessage/toastMessage";

axios.interceptors.request.use(async (request) => {
  if (!request.baseURL) return request;

  const { accessToken, ada, role, loginType } = await getLoggedInUserInfo();

  const proceedRequest = isTokenValid(accessToken);

  if (accessToken && ada && role && proceedRequest) {
    // with valid token time

    // request.headers["Authorization"] = `Bearer ${accessToken}`;
    // const payload = jwt.decode(accessToken);
    // if (typeof window === "undefined")
    //   request.headers["User-Agent"] = payload?.ua;
    request.headers = {
      ...request.headers,
      ...getAccessTokenHeaders(accessToken),
    };

    request.data = {
      ...request.data,
      loginAda: ada,
      loginMemberType: role,
      loginType: loginType,
    };

    return request;
  }

  if (isProtectedApi(request.url)) {
    let redirectCode = proceedRequest === false ? "to" : "nt";
    redirectToLogin(redirectCode);
    throw new axios.Cancel();
  }

  if (isVisitorEnabledApi(request.url)) {
    await modifyRequestWithVisitorInfo(request);
    return request;
  }

  if (request.baseURL.includes("/anon")) return request;

  request.baseURL = request.baseURL + "/anon";

  // console.log("after intercept ", request);
  return request;
}, undefined);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (failedResponse) => {
    if (failedResponse?.response?.status === 401) {
      const isUAMismatch = isUserAgentMismatch(failedResponse);

      // If user-agent error for 401 then halt the flow here and redirect to login
      if (isProtectedApi(failedResponse.config.url) || isUAMismatch) {
        // Clear session & redirect to login page

        logoutSession();
        toastMsg("頁面已過期。請重新登錄");
        setTimeout(() => {
          redirectToLogin("a");
        }, 300);
        throw new axios.Cancel();
      }

      if (isVisitorEnabledApi(failedResponse?.config?.url)) {
        const isUserInfoRemoved = removeUserInfoFromCookie();
        if (!isUserInfoRemoved) return Promise.reject(failedResponse);

        const newReq = failedResponse.config;
        await modifyRequestWithVisitorInfo(newReq);
        const newRes = await axios(newReq);
        return newRes;
      }

      const newReq = failedResponse?.config || {};
      if (!newReq.baseURL) return Promise.reject(failedResponse);

      if (newReq.baseURL.includes("/anon"))
        return Promise.reject(failedResponse);

      newReq.baseURL = newReq.baseURL + "/anon";

      //console.log("_app.js: calling api with /anon", newReq);
      const newResponse = await axios(newReq);
      return newResponse;
    }
    return Promise.reject(failedResponse);
  }
);

const isUserAgentMismatch = (failedResponse) => {
  const UA_ERROR_MSG_1 = "The user-agent doesn't match to token.";
  const UA_ERROR_MSG_2 = "The loginAda doesn’t match to token";
  const UA_ERROR_MSG_3 = "The loginAda in body doesn't match to token.";

  const message = failedResponse?.response?.data?.message;

  return (
    message == UA_ERROR_MSG_1 ||
    message == UA_ERROR_MSG_2 ||
    message == UA_ERROR_MSG_3
  );
};

const modifyRequestWithVisitorInfo = async (request) => {
  const addConsigneeData = !!request?.data?.consigneeAda;
  const { accessToken, visitorInfo } = await getVisitorInfo({
    addConsigneeData,
  });

  if (!accessToken || !visitorInfo.loginAda)
    throw new Error("failed to get visitor info");
  request.headers = {
    ...request.headers,
    ...getAccessTokenHeaders(accessToken),
  };

  const body =
    typeof request.data === "string" ? JSON.parse(request.data) : request.data;

  request.data = {
    ...body,
    ...visitorInfo,
  };
};
