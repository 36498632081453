import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import reactHtmlParser from "react-html-parser";
import Slider from "react-slick";

import { fetchTutorialsData } from "../../../utils/api";
import styles from "./tutorial.scss";

const getHidePreferences = () =>
  JSON.parse(localStorage.getItem("AMY_HIDE_TUTORIAL_PREFERENCES")) ?? {};

const setHidePreferences = (hidePreferences) =>
  localStorage.setItem(
    "AMY_HIDE_TUTORIAL_PREFERENCES",
    JSON.stringify(hidePreferences)
  );

function Tutorial(props) {
  const { pageId } = props;
  const [tutorialData, setTutorialData] = useState(null);
  const breakpoint = useSelector((state) => state.breakpoint);
  const [slideIndex, setSlideIndex] = useState(0);
  const isDesktop = breakpoint === "md" || breakpoint == "lg";

  const [checked, setChecked] = useState(false);

  const sliderSettings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // beforeChange: (current, next) => setSlideIndex(next),
  };

  const sliderRef = useRef();

  useEffect(() => {
    const hidePreferences = getHidePreferences();
    const isHide = hidePreferences?.[pageId];
    if (isHide) return;
    if (!pageId) return;
    fetchTutorialsData(pageId).then((res) => setTutorialData(res.data));
  }, [pageId]);

  useEffect(() => {
    if (!sliderRef?.current) return;
    sliderRef?.current?.slickGoTo(slideIndex);
  }, [slideIndex, sliderRef]);

  const onChangeSlide = (direction) => () =>
    setSlideIndex((i) => i + direction);

  const onClose = () => {
    if (checked) {
      setHidePreferences({
        ...getHidePreferences(),
        [pageId]: true,
      });
    }
    setTutorialData(null);
  };

  if (!tutorialData || !pageId) return null;

  const tutorialDetails = isDesktop
    ? tutorialData?.desktopTutorials
    : tutorialData?.mobileTutorials;

  const isCustomTemplate = tutorialData?.template === "custom";

  return (
    <div className={styles["modal-background"]}>
      <div className={styles["modal"]}>
        <div
          className={`${styles["wrapper"]} ${
            isCustomTemplate ? styles["custom"] : ""
          }`}
        >
          <button className={styles["close-button"]} onClick={onClose}>
            {/* <svg
              // width="20"
              // height="20"
              // viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.9743 10L19.7067 3.26761C20.0961 2.87824 20.0961 2.24637 19.7067 1.85638L18.1424 0.292027C17.753 -0.0973422 17.1212 -0.0973422 16.7312 0.292027L9.99938 7.02505L3.26698 0.292652C2.87761 -0.0967172 2.24575 -0.0967172 1.85575 0.292652L0.292027 1.85638C-0.0973423 2.24575 -0.0973423 2.87761 0.292027 3.26761L7.02443 10L0.292027 16.7324C-0.0973423 17.1218 -0.0973423 17.7536 0.292027 18.1436L1.85638 19.708C2.24575 20.0973 2.87761 20.0973 3.26761 19.708L9.99938 12.975L16.7318 19.7073C17.1212 20.0967 17.753 20.0967 18.143 19.7073L19.7074 18.143C20.0967 17.7536 20.0967 17.1218 19.7074 16.7318L12.9743 10Z"
                fill="#FAFAFA"
              />
            </svg> */}
            <svg fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.3795 8L15.7654 2.61408C16.0769 2.30259 16.0769 1.7971 15.7654 1.4851L14.5139 0.233621C14.2024 -0.0778738 13.6969 -0.0778738 13.3849 0.233621L7.9995 5.62004L2.61358 0.234121C2.30209 -0.0773738 1.7966 -0.0773738 1.4846 0.234121L0.233621 1.4851C-0.0778738 1.7966 -0.0778738 2.30209 0.233621 2.61408L5.61954 8L0.233621 13.3859C-0.0778738 13.6974 -0.0778738 14.2029 0.233621 14.5149L1.4851 15.7664C1.7966 16.0779 2.30209 16.0779 2.61408 15.7664L7.9995 10.38L13.3854 15.7659C13.6969 16.0774 14.2024 16.0774 14.5144 15.7659L15.7659 14.5144C16.0774 14.2029 16.0774 13.6974 15.7659 13.3854L10.3795 8Z"
                fill="white"
              />
            </svg>
          </button>
          <div className={styles["header"]}>
            {isCustomTemplate ? (
              <img
                className={styles["header-image"]}
                src={tutorialDetails[slideIndex]?.headerImage}
              />
            ) : (
              <>
                <h1 className={styles["title"]}>
                  {tutorialDetails[slideIndex]?.headerTitle}
                </h1>
                <div className={styles["description"]}>
                  {reactHtmlParser(
                    tutorialDetails[slideIndex]?.headerDescription
                  )}
                </div>
              </>
            )}
          </div>
          <div className={styles["body"]}>
            <div className={styles["details-wrapper"]}>
              <Slider ref={sliderRef} {...sliderSettings}>
                {tutorialDetails?.map((tutorialDetail, i) => (
                  <div className={styles["details"]}>
                    {reactHtmlParser(tutorialDetail.details)}
                  </div>
                ))}
              </Slider>
            </div>
          </div>
          <div
            className={`${styles["footer"]} ${
              tutorialDetails.length > 1 ? "" : styles["active"]
            }`}
          >
            <div className={styles["checkbox-wrapper"]}>
              <div
                className={styles["content-checkbox"]}
                onClick={() => setChecked(!checked)}
              >
                <input type="checkbox" checked={checked} />
                <div className={styles["content"]}>
                  <svg
                    width="14"
                    height="12"
                    viewBox="0 0 14 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5.74494 10.9849L13.7949 2.93492C14.0683 2.66154 14.0683 2.21833 13.7949 1.94497L12.805 0.955018C12.5317 0.681661 12.0884 0.681661 11.8151 0.955018L5.24998 7.52009L2.1849 4.45501C1.91154 4.18163 1.4683 4.18163 1.19494 4.45501L0.205018 5.44496C-0.0683392 5.71835 -0.0683392 6.16156 0.205018 6.43492L4.75502 10.9849C5.02835 11.2583 5.47156 11.2583 5.74494 10.9849Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
              <p>下次不要再出現</p>
            </div>
            {tutorialDetails?.length > 1 ? (
              <div className={styles["arrows"]}>
                <button
                  className={styles["prev-arrow"]}
                  onClick={onChangeSlide(-1)}
                  disabled={slideIndex <= 0}
                >
                  <svg
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M5 9L1 5L5 1"
                      stroke="inherit"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <button
                  className={styles["next-arrow"]}
                  onClick={onChangeSlide(1)}
                  disabled={slideIndex >= tutorialDetails?.length - 1}
                >
                  <svg
                    width="6"
                    height="10"
                    viewBox="0 0 6 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 1L5 5L1 9"
                      stroke="inherit"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            ) : (
              <button className={styles["close-tutorial"]} onClick={onClose}>
                關閉
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tutorial;
