import { intlReducer } from "react-intl-redux";
import announcementReducer from "./announcement-reducer";
// import HFReducer from '@ind-refresh/Header-Footer/src/redux/reducer';
import appVariablesReducer from "./app-variables-reducer";
import breakpointReducer from "./breakpoint-reducer";

import hfReduxModule from "@tw/header-footer/lib/redux/nextjs-modules";
import loginReduxModule from "@tw/customer-identity-cra/lib/login/redux/nextjs-modules";
import switchRecipientReduxModule from "@tw/shared-ui-components/lib/components/switchRecipientDetails/redux/nextjs-modules"
// TBD - move 'hfReduxModule' to @ind-refresh/Header-Footer and import from there
// const hfReduxModule = {
//   id: '@dnd/hf',
//   reducerMap: HFReducer
// }

export default [
  {
    id: "base",
    reducerMap: {
      intl: intlReducer,
      appVariables: appVariablesReducer,
      breakpoint: breakpointReducer,
      // announcement: announcementReducer,
    },
  },
  hfReduxModule,
  loginReduxModule,
  switchRecipientReduxModule
];
